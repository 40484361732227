import * as React from 'react'
import styled from '@emotion/styled'

const StyledPage = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  background-color: #000;
  padding-left: 5rem;
  padding-right: 5rem;

  @media only screen and (max-width: 1500px) {
    padding-left: 0rem;
    padding-right: 0rem;
  }
`

interface PageProps {
  className?: string
  children: React.ReactNode
}

export function Page({ children, className }: PageProps) {
  return <StyledPage className={className}>{children}</StyledPage>
}

export default Page
